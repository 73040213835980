import React from "react";
import { FiMapPin } from "react-icons/fi";
import { TiStarFullOutline } from "react-icons/ti";
import { MdOutlineFileDownload } from "react-icons/md";
import { Link } from "react-router-dom";
import { Services } from "../../Services";

const CollegeList = ({ colleges }) => {
  const stopPropagation = async (e, collegeId) => {
    e.stopPropagation();
    try {
      const res = await Services.GetBrochureLink("GET", null, token, collegeId); 
      console.log(res);
      if (res && res.brochure) {
        window.open(res.brochure.brochureLink, "_blank");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const truncateText = (text, maxLength) => {
    if (!text) return "-";
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };
  

  

  return (
    <div>
        <Link
            to={`/colleges/CollegeDetails`}
            className="University-card-link"
          >
      <div className="University-card-row">
        {colleges.map((college, index) => (
          <div className="University-card" key={index} >
            <img
              src={college.collegePhoto}
              alt={college.collegeName}
              className="university-img"
            />

            <div className="University-card-details">
              <h2 className="University-name">
                {college.collegeName ? college.collegeName : "-"}
              </h2>
              <div className="University-address-rating">
                <p className="University-address">
                  <FiMapPin className="University-address-icon" />
                  {truncateText(college.collegeLocation, 60)}
                </p>
                <p className="University-rating">
                  <TiStarFullOutline className="University-address-icon-star" />
                  {college.collegeRating ? college.collegeRating : "-"}
                </p>
              </div>

              <div className="University-box">
                <div className="University-box-inside">
                  <div className="University-box-inside-one">
                    <h5 className="University-heading">Courses Offered</h5>
                    <p className="University-heading-values">
                      {college.coursesOffered ? college.coursesOffered : "-"}
                    </p>
                  </div>
                  <div className="University-box-inside-one">
                    <h5 className="University-heading2">Work Experience</h5>
                    <p className="University-heading-values">
                      {college.workExperience ? college.workExperience : "-"}
                    </p>
                  </div>
                </div>

                <div className="University-box-inside2">
                  <div className="University-box-inside-one">
                    <h5 className="University-heading">1st Year Tuition Fees</h5>
                    <p className="University-heading-values">
                      {college.firstYearFees ? college.firstYearFees : "-"}
                    </p>
                  </div>
                  <div className="University-box-inside-one">
                    <h5 className="University-heading2">No. of Scholarships</h5>
                    <p className="University-heading-values">
                      {college.noOfScholarships ? college.noOfScholarships : "-"}
                    </p>
                  </div>
                </div>

                <div className="University-box-inside">
                  <div className="University-box-inside-one">
                    <h5 className="University-heading">Exams Accepted</h5>
                    <p className="University-heading-values">
                      {college.exams ? college.exams.join(", ") : "-"}
                    </p>
                  </div>
                  <div className="University-box-inside-one">
                    <h5 className="University-heading2">Intake Session</h5>
                    <p className="University-heading-values">
                      {college.intakeSession ? college.intakeSession : "-"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="University-buttons">
                <div>
                  <button
                    className="University-brocher-button"
                    onClick={(e) => stopPropagation(e, college.collegeId)}
                  >
                    <MdOutlineFileDownload /> Brochure
                  </button>
                </div>
                <div>
                  <Link
                    to={`/colleges/Ratemychance?CollegeId=${college.collegeId}`}
                    className="University-rating-button"
                  >
                    Rate My Chance
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      </Link>
    </div>
  );
};

export default CollegeList;
