import {
  Duration,
  ESLAvailable,
  ProgramLevel,
  Requirements,
  countries,
  disciplineAreas,
  studyAreas,
} from "./Data";
import React, { useState, useEffect } from "react";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessages";
import AdvanceClasses from "./Home.module.css";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import { Services } from "../../Services";
import { useNavigate } from "react-router-dom";
import { IoSearchSharp } from "react-icons/io5";

const Months = [  "January",  "February",  "March",  "April",  "May",  "June",  "July",  "August",  "September",  "October",  "November",  "December",];
const Years = ["2024", "2025", "2026", "2027", "2028"];

const AllMonths = Months.map((month, index) => ({
  label: month,
  value: index + 1,
}));
const AllYears = Years.map((year, index) => ({ label: year, value: year }));

function Searchbar({ setShowAdvancedSearch, showAdvancedSearch, results }) {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMonths, setSelectedMonths] = useState(AllMonths);

  const currentYear = new Date().getFullYear().toString();
  const [selectedYear, setSelectedYear] = useState(
    AllYears.filter((year) => year.label === currentYear)
  );

  const [collegeLocation, setCollegeLocation] = useState("");
  const [stateSuggestions, setStateSuggestions] = useState([]);

  const [selectedProgramLevels, setSelectedProgramLevels] = useState([]);
  const [selectedCountries, setSelectedcountries] = useState([]);

  const [selectedStudyArea, setSelectedStudyArea] = useState([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState([]);
  const [disciplineOptions, setDisciplineOptions] = useState([]);

  const [selectedDuration, setSelectedDuration] = useState([]);
  const [selectedESL, setSelectedESL] = useState(null);

  const [reqs, setReqs] = useState({});

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  const handleSelectMonth = (selectedList) => {
    setSelectedMonths(selectedList);
  };

  const handleSelectYear = (selectedList) => {
    setSelectedYear(selectedList);
  };

  const handleStateChange = async (event) => {
    const query = event.target.value;
    setCollegeLocation(query);
    if (query) {
      try {
        const response = await Services.Getstatessearch("GET", null, query);
        if (response.Status === 1) {
          setStateSuggestions(response.States);
        } else {
          setStateSuggestions([]);
        }
      } catch (error) {
        setStateSuggestions([]);
        ToastError("Failed to fetch state suggestions.");
      }
    } else {
      setStateSuggestions([]);
    }
  };

  const handleStateSelect = (state) => {
    setCollegeLocation(state);
    setStateSuggestions([]);
  };

  const handleProgramLevelChange = (event) => {
    const temp = [...selectedProgramLevels];
    const { checked, value } = event.target;
    if (checked) {
      if (!temp.includes(value)) temp.push(value);
    } else {
      setSelectedProgramLevels((curr) => curr.filter((x) => x !== value));
    }
    setSelectedProgramLevels(temp);
  };

  const handleCountriesChange = (options) => {
    const temp = options.map((e) => e.value);
    setSelectedcountries(temp);
  };

  const handleStudyAreaChange = (options) => {
    const temp = options.map((e) => e.value);
    const labels = options.map((e) => e.label);
    setSelectedStudyArea(temp);
    const disciplineOptions = labels.flatMap((area) => {
      return (
        disciplineAreas.find((item) => item.area === area)?.options || []
      );
    });
    setDisciplineOptions(disciplineOptions);
  };

  const handleDisciplineChange = (options) => {
    const temp = options.map((e) => e.value);
    setSelectedDiscipline(temp);
  };

  const handleDurationChange = (selectedOption) => {
    const temp = selectedOption.value;
    setSelectedDuration(temp);
  };

  const handleESLChange = (selectedOption) => {
    const temp = selectedOption.value;
    setSelectedESL(temp);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleReqChange = (event) => {
    const temp = { ...reqs };
    const key = event.target.name;
    const checked = event.target.checked;
    if (checked) {
      temp[key] = 1;
      setReqs(temp);
    } else {
      delete temp[key];
    }
    setReqs(temp);
  };

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const SearchButton = async (resultsData) => {
    if (!localStorage.getItem("token")) {
      ToastError("Please login to perform this search.");
      return;
    }
    try {
      if (
        selectedMonths.length &&
        selectedYear.length &&
        (searchTerm ||
          selectedProgramLevels.length ||
          selectedCountries.length ||
          selectedStudyArea.length ||
          selectedDiscipline.length ||
          selectedDuration.length ||
          selectedESL !== null ||
          Object.keys(reqs).length)
      ) {
        const data = {
          intakeMonths: selectedMonths.map((month) => month.value),
          intakeYears: selectedYear.map((year) => year.value),
        };
        if (searchTerm) data.searchTerm = searchTerm;
        if (collegeLocation) data.collegeLocation = collegeLocation;
        if (selectedProgramLevels.length) data.programLevels = selectedProgramLevels;
        if (selectedCountries.length) data.countryCodes = selectedCountries;
        if (selectedStudyArea.length) data.categoryIds = selectedStudyArea;
        if (selectedDiscipline.length) data.subcategoryIds = selectedDiscipline;
        if (selectedDuration.length) data.courseDuration = selectedDuration;
        if (selectedESL !== null) data.isEslElpAvailable = selectedESL;

        const finalData = showAdvancedSearch
          ? { ...data, ...reqs }
          : { ...data };

        const res = await Services.Searchbutton(
          "POST",
          JSON.stringify(finalData),
          token
        );
        if (res.Status === 1) {
          ToastSuccess("Courses found successfully.");
          navigate("/Searchpage", { state: res });
        } else {
          ToastError(res.Error);
        }
      } else {
        const errKeys = [];
        let errMessage = "";
        if (showAdvancedSearch) {
          if (!selectedMonths.length) errKeys.push("month");
          if (!selectedYear.length) errKeys.push("year");
          if (
            !selectedProgramLevels.length &&
            !selectedCountries.length &&
            !selectedStudyArea.length &&
            !selectedDiscipline.length &&
            !selectedDuration.length &&
            selectedESL === null &&
            !Object.keys(reqs).length
          ) {
            errKeys.push("at least one field in the Advanced Search");
          }
          if (errKeys.length > 1) {
            const tmp = errKeys.pop();
            errMessage = `${errKeys.join(", ")} and ${tmp}`;
          } else {
            errMessage = errKeys[0];
          }
          ToastError(`Please select ${errMessage}`);
        } else {
          if (!searchTerm.length) errKeys.push("course");
          if (!selectedMonths.length) errKeys.push("month");
          if (!selectedYear.length) errKeys.push("year");
          if (errKeys.length > 1) {
            const tmp = errKeys.pop();
            errMessage = `${errKeys.join(", ")} and ${tmp}`;
          } else {
            errMessage = errKeys[0];
          }
          ToastError(`Please enter ${errMessage}`);
        }
      }
    } catch (err) {
      ToastError(err.message || "Something went wrong. Please try again.");
    }
  };

  return (
    <div className={AdvanceClasses["search-container"]}>
      <div
        className={`${AdvanceClasses["search-container-inside"]} ${
          showAdvancedSearch
            ? AdvanceClasses["advanced-search-open"]
            : AdvanceClasses["advanced-search-closed"]
        }`}
      >
        <div className={AdvanceClasses["search-container-main"]}>
          <div className={AdvanceClasses["search-container-one"]}>
            <div className={AdvanceClasses["search-box"]}>
              <label>Search Course</label>
              <input
                type="text"
                placeholder="Search for Course | University |"
                onChange={handleSearchTermChange}
                className={AdvanceClasses["search-inbox"]}
              />
            </div>

            <div className={AdvanceClasses["dropdown"]}>
              <label>Intake</label>
              <MultiSelect
                options={AllMonths}
                value={selectedMonths}
                onChange={handleSelectMonth}
                labelledBy="Select"
                className={AdvanceClasses["search-inbox2"]}
              />
            </div>

            <div className={AdvanceClasses["dropdown"]}>
              <label>Year</label>
              <MultiSelect
                options={AllYears}
                value={selectedYear}
                onChange={handleSelectYear}
                labelledBy="Select"
                className={AdvanceClasses["search-inbox2"]}
              />
            </div>

            <div className={AdvanceClasses["search-box"]}>
              <label>Search State</label>
              <input
                type="text"
                placeholder="Province | State"
                className={AdvanceClasses["search-inbox"]}
                value={collegeLocation}
                onChange={handleStateChange}
              />
              {stateSuggestions.length > 0 && (
                <ul className={AdvanceClasses["search-states-list"]}>
                  {stateSuggestions.map((state, index) => (
                    <li
                      key={index}
                      onClick={() => handleStateSelect(state)}
                      className={AdvanceClasses["state-dropdown"]}
                    >
                      {state}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div>
            <button
              className={AdvanceClasses["search-button"]}
              onClick={() => SearchButton(results)}
            >
              <IoSearchSharp/>Search
            </button>
          </div>

        </div>
        <div className={AdvanceClasses["search-container-two"]}>
          <button
            className={AdvanceClasses["search-advance"]}
            onClick={toggleAdvancedSearch}
          >
            {showAdvancedSearch ? "Close" : "+ Advanced Search"}
          </button>
        </div>

        {showAdvancedSearch && (
          <div className={AdvanceClasses["advanced-search-options"]}>
            <div className={AdvanceClasses["advanced-program-level"]}>
              <h3>Program Level</h3>
              {ProgramLevel.map((option, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    name="programLevel"
                    value={option.value}
                    onChange={handleProgramLevelChange}
                  />
                  {option.label}
                </label>
              ))}
            </div>

            <div className={AdvanceClasses["advanced-Country"]}>
              <h3>Country</h3>

              <Select
                options={countries.map((country, index) => ({
                  value: index + 1,
                  label: country,
                }))}
                onChange={handleCountriesChange}
                isMulti
              />

              <h3>Study Area</h3>
              <Select
                options={studyAreas.map((area, index) => ({
                  value: index + 1,
                  label: area,
                }))}
                onChange={handleStudyAreaChange}
                isMulti
              />

              <h3>Discipline Area</h3>
              <Select
                options={disciplineOptions.map((area, index) => ({
                  value: area.value,
                  label: area.label,
                }))}
                onChange={handleDisciplineChange}
                isMulti
              />

              <h3>Duration</h3>
              <Select options={Duration} onChange={handleDurationChange} />

              <h3>ESL/ELP Available</h3>

              <Select options={ESLAvailable} onChange={handleESLChange} />
            </div>

            <div className={AdvanceClasses["advanced-requirements"]}>
              <h3>Requirements</h3>
              {Requirements.map((option, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    name={option.name}
                    value={option.value}
                    onChange={handleReqChange}
                  />
                   {option.label}
                </label>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Searchbar;